<div class="add-player">
  <mat-form-field appearance="outline" class="big">
    <mat-label>Player name</mat-label>
    <input #playerNameInput type="text" matInput placeholder="Enter name"
      [disabled]="!playground.canAddPlayer()" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="playerSelected($event, playerNameInput)">
      <mat-option *ngFor="let option of getOptions()" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button mat-icon-button (click)="addPlayer(playerNameInput)" [disabled]="!playground.canAddPlayer()"><mat-icon>add_circle</mat-icon></button>
</div>
<div class="player-table">
  <div class="player-row" *ngFor="let player of playground.game.players; let i = index">
    <div class="player-name">{{player.name}}</div>
    <button mat-icon-button (click)="playground.removePlayer(player)"><mat-icon>remove_circle</mat-icon></button>
  </div>
</div>

import {Component, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {GameService} from '~services/game.service';
import {Playground} from '~models/playground.model';
import {ApplicationStateService} from '~services/application-state.service';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements AfterViewInit{

  
  @ViewChild('input') inputElement: ElementRef
  @Input() playground: any;
  public numbers: number[] = [
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,25,26,27,28,30,32,33,34,36,38,39,40,42,45,48,50,51,54,57,60
  ]

    constructor(public game: GameService,
              public application: ApplicationStateService) {
  }
  ngAfterViewInit(){
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.inputElement.nativeElement.focus();
    },0);
  }

  onKeyDown(event): void{
    let stringValue = event.target.value
    let value = Number(event.target.value) - 1;

    if (this.numbers.indexOf(value + 1) !== -1 && event.key === 'Enter') {
      if(!this.isNumberDisabled(value)){
        this.throwNumber(value)

        event.target.value = "";
        this.inputElement.nativeElement.focus();
      }
    }

    if (event.key === '-') {
      this.playground.undo();
      event.target.value = "";
    }

    if(event.code === 'Space') {
      this.playground.throwNumber([0,0]);
      event.target.value = "";
    }

    if(event.code === 'ArrowRight'){
      this.playground.nextPlayer();
      event.target.value = "";
      this.inputElement.nativeElement.focus();  
    }

  }

  getNumberColor(fieldIndex: number): string {
    if (this.playground.isPrimaryField(fieldIndex)) {
      return 'primary';
    } else if (this.playground.isSecondaryField(fieldIndex)) {
      return 'accent';
    }
    return '';
  }

  isNumberDisabled(fieldIndex: number): boolean {
    return !this.playground.isFieldEnabled(fieldIndex) && !this.getNumberColor(fieldIndex);
  }

  throwNumber(fieldIndex: number) {
    this.playground.throwNumber([this.playground.isFieldEnabled(fieldIndex) ? Playground.getFieldValueFromIndex(fieldIndex) : 0,
      Playground.getFieldValueFromIndex(fieldIndex)]);
  }
}


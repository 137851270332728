<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Games</h2>
    <div class="field-set">
      <mat-form-field appearance="outline" class="big">
        <mat-label>Life limit</mat-label>
        <input [(ngModel)]="settings.startPoint" type="number" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="big">
        <mat-label>Boarding</mat-label>
        <input [(ngModel)]="settings.targetPoint" type="number" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline" class="big">
        <mat-label>Punishment</mat-label>
        <input [(ngModel)]="settings.punishment" type="number" matInput>
      </mat-form-field>
    </div>

    <br/>

    <button class="button shortmedium" [class.primary]="settings.handover ? 'primary' : ''"
            (click)="settings.toggleHandOver()">Hand Over</button>

    <br/>
    <mat-divider></mat-divider>

    <h2>Fields</h2>
    <button class="button" [class.primary]="settings.isFieldAllowed(i)"
            *ngFor="let numb of settings.getNumbers(); let i = index" (click)="settings.setNumber(i)">
      {{numb === 21 ? 'B' : numb}}
    </button>
    <mat-divider></mat-divider>
    <button class="button shortmedium" (click)="settings.top()">Top</button>
    <button class="button shortmedium" (click)="settings.bottom()">Bottom</button>
    <button class="button shortmedium" (click)="settings.left()">Left</button>
    <button class="button shortmedium" (click)="settings.right()">Right</button>
    <button class="button shortmedium" (click)="settings.black()">Black</button>
    <button class="button shortmedium" (click)="settings.white()">White</button>
    <button class="button shortmedium" (click)="settings.high()">High</button>
    <button class="button shortmedium" (click)="settings.small()">Small</button>
    <button class="button shortmedium" (click)="settings.odd()">Odd</button>
    <button class="button shortmedium" (click)="settings.even()">Even</button>
    <button class="button shortmedium" (click)="settings.prime()">Prime</button>
    <button class="button shortmedium" (click)="settings.notPrime()">Not prime</button>
    <button class="button shortmedium" (click)="settings.cross()">Cross</button>
    <button class="button shortmedium" (click)="settings.randomize(6)">Random 06</button>
    <button class="button shortmedium" (click)="settings.randomize(12)">Random 12</button>
    <button class="button shortmedium" (click)="settings.all()">All</button>

  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}} {{isInactive(player) ? 'inactive' : ''}}"
          *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [hasContent]="false"></app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

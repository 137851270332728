<div class="side-right" [class.detailed]="application.settings.details">

  <ng-container *ngFor="let i of getNumbers()">
    <button class="button number {{getNumberColor(i-1)}}"
            [disabled]="isNumberDisabled(i-1)" (click)="throwNumber(i-1)">
      <mat-icon *ngIf="playground.getFieldIcon(i-1)">{{ playground.getFieldIcon(i - 1)}}</mat-icon>
      <p class="field-note" *ngIf="playground.getFieldNote(i-1)">{{ playground.getFieldNote(i - 1)}}</p>
      <div class="field-number">{{i}}</div>
    </button>
  </ng-container>

  <button class="button number {{getNumberColor(20)}}" [disabled]="isNumberDisabled(20)"
          (click)="throwNumber(20)">
    <mat-icon *ngIf="playground.getFieldIcon(20)">{{ playground.getFieldIcon(20)}}</mat-icon>
    <p class="field-note" *ngIf="playground.getFieldNote(20)">{{ playground.getFieldNote(20)}}</p>
    <div class="field-number">Bull</div>
  </button>

  <button class="button number" [class.primary]="playground.multiplier === 2"
          (click)="playground.doublePoint()" [disabled]="!playground.multiEnabled">
    <div class="field-number">2x</div>
  </button>
  <button class="button number" [class.primary]="playground.multiplier === 3"
          (click)="playground.triplePoint()" [disabled]="!playground.multiEnabled">
    <div class="field-number">3x</div>
  </button>

  <button class="button number" (click)="playground.skip()" [disabled]="!playground.nextEnabled">
    <div class="field-number">Next</div>
  </button>
  <button class="button number" (click)="playground.throwNumber([0,0])" [disabled]="!playground.zeroEnabled">
    <div class="field-number">0</div>
  </button>
</div>

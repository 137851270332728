<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Games</h2>
    <button class="button big" [class.primary]="settings.noCheat" (click)="settings.toggleNoCheat()">
      No Cheat
    </button>
    <mat-divider></mat-divider>

    <h2>Divider</h2>
    <button class="button" [class.primary]="settings.divider === divider"
            *ngFor="let divider of settings.getSelectableDividers()"
            (click)="settings.setDivider(divider)">{{divider}}</button>
    <mat-divider></mat-divider>

    <h2>Limit</h2>
    <button class="button" [class.primary]="settings.limit === limit"
            *ngFor="let limit of settings.getSelectableLimits()"
            (click)="settings.setLimit(limit)">{{limit}}</button>
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [hasContent]="false"></app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

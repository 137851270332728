<div class="toolbar">
  <button class="button icon-button" (click)="quit()">
    <mat-icon>menu</mat-icon>
  </button>
  <button class="button icon-button" (click)="showSettings()">
    <mat-icon>build</mat-icon>
  </button>
  <button class="button icon-button" (click)="newGame()">
    <mat-icon>refresh</mat-icon>
  </button>
  <button class="button icon-button" (click)="undo()">
    <mat-icon>undo</mat-icon>
  </button>
</div>

<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Number of Round</h2>
    <button class="button" [class.primary]="settings.round===3" (click)="settings.setRound(3)">
      3
    </button>
    <button class="button" [class.primary]="settings.round===5" (click)="settings.setRound(5)">
      5
    </button>
    <button class="button" [class.primary]="settings.round===10" (click)="settings.setRound(10)">
      10
    </button>
    <button class="button" [class.primary]="settings.round===15" (click)="settings.setRound(15)">
      15
    </button>
    <button class="button" [class.primary]="settings.round===20" (click)="settings.setRound(20)">
      20
    </button>
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" [reversRound]="settings.round" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [hasContent]="false"></app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Games</h2>
    <div class="field-set">
      <mat-form-field appearance="outline" class="big">
        <mat-label>Life limit</mat-label>
        <input [(ngModel)]="settings.numberOfLives" type="number" matInput>
      </mat-form-field>
    </div>
    <br/>
    <button class="button big" [class.primary]="settings.killer" (click)="settings.toggleKiller()">
      Below the belt
    </button>

  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" [info]="getInfo()" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}} {{player.isInactive() ? 'inactive' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [scoreDisplayed]="true" [lifeDisplayed]="true" [hasContent]="false">
        </app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

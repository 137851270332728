<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Fields</h2>
    <button class="button" [class.primary]="settings.numbs[i]"
            *ngFor="let numb of settings.getNumbers(); let i = index" (click)="settings.setNumber(i)">
      {{numb === 21 ? 'B' : numb}}
    </button>
    <mat-divider></mat-divider>

    <button class="button shortmedium" (click)="settings.randomize()">Randomize</button>
    <button class="button shortmedium"  [class.primary]="settings.noScore"
            (click)="settings.toggleNoScore()">No score
    </button>
    <button class="button shortmedium"  [class.primary]="settings.halveIt"
            (click)="settings.toggleHalveIt()">Halve It
    </button>
    <button class="button shortmedium"  [class.primary]="settings.resetIt"
            (click)="settings.toggleResetIt()">Reset It
    </button>
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player">
          <div class="field-set">
            <div class="field {{isActiveField(field) ? 'active': ''}} {{isFieldDoneForPlayer(field) ? '' : 'highlighted'}}"
                 *ngFor="let field of settings.fields">
              {{field === 20 ? 'B' : (field + 1)}}: <span class="small">{{ getFieldValue(player, field) }}</span>
            </div>
          </div>
        </app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
  <app-number-plate-shanghai [playground]="playground" [actualIndex]="getActualFieldIndex()"
                             *ngIf="!settingsOpen && !application.settings.details"></app-number-plate-shanghai>
</div>

<div class="side-right" [class.detailed]="application.settings.details">


  <input class="mdc-text-field--outlined" #input id="scoreInput" type="text" autocomplete="off" (keydown)="onKeyDown($event)">

  <!-- <button class="button number" (click)="playground.skip()" [disabled]="!playground.nextEnabled">
    <div class="field-number">Next</div>
  </button>
  <button class="button number" (click)="playground.throwNumber([0,0])" [disabled]="!playground.zeroEnabled">
    <div class="field-number">0</div>
  </button> -->
</div>

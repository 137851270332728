<div class="dialog-container" [class.mobile]="application.isMobile">
  <div mat-dialog-title class="subtitle-1"><p>{{ data.title }}</p></div>
  <mat-dialog-content class="body-2" *ngIf="!!data.content">
    <p>{{ data.content }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button primary" (click)="confirm()">OK</button>
    <button class="button" (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</div>

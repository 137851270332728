<div class="dialog-container">
  <div mat-dialog-title class="subtitle-1"><p>{{ data.title }}</p></div>
  <mat-dialog-content class="body-2" *ngIf="!!data.content || !!data.players.length">
    <p>{{ data.content }}</p>
    <div class="score-line" *ngFor="let player of data.players; let i = index" [class.winner]="player.win">
      <div>{{i+1}}. {{player.name}} <mat-icon color="warn" *ngIf="player.win">star</mat-icon></div>
      <div>{{player.score}}</div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [mat-dialog-close]="true" class="button primary">Ok</button>
  </mat-dialog-actions>
</div>

<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Hound start position</h2>
    <button class="button" [class.primary]="(settings.houndStartIndex+1) === start"
            *ngFor="let start of settings.getSelectableHoundStart()"
            (click)="settings.setHoundStart(start - 1)">{{start}}</button>

  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [hasContent]="false"></app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

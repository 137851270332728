<div class="settings">
  <h1>{{playground.gameTitle}} Settings</h1>
  <h2>Players</h2>
  <app-player-settings [playground]="playground"></app-player-settings>
  <mat-divider></mat-divider>
  <ng-content></ng-content>
  <mat-divider></mat-divider>
  <button class="button shortmedium" (click)="openStatistics()">Rank</button>
  <button class="button shortmedium" (click)="openHistory()">History</button>
  <mat-divider></mat-divider>
  <button class="button shortmedium" color="primary" (click)="playground.newGame()">Start</button>
  <button class="button shortmedium" (click)="playground.quit()">Cancel</button>
</div>

<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Fields</h2>
    <button class="button" [class.primary]="settings.isFieldAllowed(i)"
            *ngFor="let numb of settings.getNumbers(); let i = index" (click)="settings.setNumber(i)">
      {{numb === 21 ? 'B' : numb}}
    </button>
    <mat-divider></mat-divider>
    <button class="button shortmedium" (click)="settings.top()">Top</button>
    <button class="button shortmedium" (click)="settings.bottom()">Bottom</button>
    <button class="button shortmedium" (click)="settings.left()">Left</button>
    <button class="button shortmedium" (click)="settings.right()">Right</button>
    <button class="button shortmedium" (click)="settings.black()">Black</button>
    <button class="button shortmedium" (click)="settings.white()">White</button>
    <button class="button shortmedium" (click)="settings.high()">High</button>
    <button class="button shortmedium" (click)="settings.small()">Small</button>
    <button class="button shortmedium" (click)="settings.odd()">Odd</button>
    <button class="button shortmedium" (click)="settings.even()">Even</button>
    <button class="button shortmedium" (click)="settings.prime()">Prime</button>
    <button class="button shortmedium" (click)="settings.notPrime()">Not prime</button>
    <button class="button shortmedium" (click)="settings.cross()">Cross</button>
    <button class="button shortmedium" (click)="settings.randomize(6)">Random 06</button>
    <button class="button shortmedium" (click)="settings.randomize(12)">Random 12</button>
    <button class="button shortmedium" (click)="settings.all()">All</button>
    <mat-divider></mat-divider>

    <h2>Style</h2>
    <button class="button shortmedium" [class.primary]="settings.style==1"
            (click)="settings.setStyle(1)">Classic
    </button>
    <button class="button shortmedium" [class.primary]="settings.stopper"
            (click)="settings.toggleStopper()">Stopper
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==3"
            (click)="settings.setStyle(3)">No Score
    </button>
    <button class="button shortmedium" [class.primary]="settings.punishment"
            (click)="settings.togglePunishment()">Punish({{getPunishValue()}})
    </button>
    <button class="button shortmedium" [class.primary]="settings.promoter" (click)="settings.togglePromoter()">
      Promoter
    </button>
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [hasContent]="false"
          [highlighted]="settings.stopper && game.isTheFirstPlayer(player)"
          [scoreDisplayed]="!settings.stopper || !game.isTheFirstPlayer(player)">
        </app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

<div class="player_info" [class.player_info_visual]="!application.settings.details">
  <div class="name">
    <mat-icon *ngIf="highlighted">offline_bolt</mat-icon>
    {{player.name}}</div>
  <div class="shoot-score">
    <div class="shoots">
      <div class="shoot">{{!player.throws[0] ? '-' : player.throws[0]}}</div>
      <div class="shoot">{{!player.throws[1] ? '-' : player.throws[1]}}</div>
      <div class="shoot">{{!player.throws[2] ? '-' : player.throws[2]}}</div>
      <div class="shoot total" *ngIf="scoreDisplayed">{{!player.getThrowsTotal() ? '0' : player.getThrowsTotal()}}</div>
    </div>
    <div class="score" *ngIf="scoreDisplayed">{{player.score}}</div>
    <div class="score" *ngIf="lifeDisplayed"><label>Life: </label>{{player.life}}</div>
    <div class="clear"></div>
  </div>
</div>

<div id="playgroundContent">
        <div class="number-input-container">
          <!-- <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
          </app-number-plate-visual>
          <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate> -->
          <app-number-input [playground]="playground" *ngIf="!settingsOpen"></app-number-input>
        </div>

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Games</h2>
    <button class="button" [class.primary]="settings.startValue===101"
            (click)="settings.setStartValue(101)">101
    </button>
    <button class="button" [class.primary]="settings.startValue===201"
            (click)="settings.setStartValue(201)">201
    </button>
    <button class="button" [class.primary]="settings.startValue===301"
            (click)="settings.setStartValue(301)">301
    </button>
    <button class="button" [class.primary]="settings.startValue===501"
            (click)="settings.setStartValue(501)">501
    </button>
    <button class="button" [class.primary]="settings.startValue===701"
            (click)="settings.setStartValue(701)">701
    </button>
    <button class="button" [class.primary]="settings.startValue===901"
            (click)="settings.setStartValue(901)">901
    </button>

    <mat-divider></mat-divider>
    <button class="button shortmedium" [class.primary]="settings.saboteur" (click)="settings.toggleSaboteur()">
      Elimination
    </button>
    <button class="button shortmedium" [class.primary]="settings.punishment" (click)="settings.togglePunishment()">
      Punishment
    </button>
    <mat-divider></mat-divider>

    <h2>Check In</h2>
    <button class="button shortmedium" [class.primary]="settings.start===1"
            (click)="settings.setStart(1)">Normal
    </button>
    <!-- <button class="button shortmedium" [class.primary]="settings.start===2"
            (click)="settings.setStart(2)">Double
    </button>
    <button class="button shortmedium" [class.primary]="settings.start===3"
            (click)="settings.setStart(3)">Triple
    </button> -->
    <mat-divider></mat-divider>

    <h2>Check Out</h2>
    <button class="button shortmedium" [class.primary]="settings.checkout===1"
            (click)="settings.setCheckout(1)">Single
    </button>
    <!-- <button class="button shortmedium" [class.primary]="settings.checkout===2"
            (click)="settings.setCheckout(2)">Double
    </button>
    <button class="button shortmedium" [class.primary]="settings.checkout===3"
            (click)="settings.setCheckout(3)">Triple
    </button>
    <button class="button shortmedium" [class.primary]="settings.checkout===4"
            (click)="settings.setCheckout(4)">High Score
    </button> -->
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen" [info]="getInfo()"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [hasContent]="false"></app-player-score>
      </div>
    </div>
  </div>

</div>

<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <button class="button shortmedium" [class.primary]="settings.style === 0"
            (click)="settings.randomCity()">City (5)
    </button>
    <button class="button shortmedium" [class.primary]="settings.style === 1"
            (click)="settings.randomCountry()">Country (10)
    </button>
    <button class="button shortmedium" [class.primary]="settings.style === 2"
            (click)="settings.randomContinent()">Contin. (20)
    </button>
    <button class="button shortmedium" [class.primary]="settings.noScore"
            (click)="settings.toggleNoScore()">No score
    </button>
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="player {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player"></app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>

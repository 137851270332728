<div id="playgroundContent">

  <app-settings *ngIf="settingsOpen" [playground]="playground">
    <h2>Games</h2>
    <button class="button shortmedium" [class.primary]="settings.jump" (click)="settings.toggleJump()">
      Enable Jump
    </button>
    <button class="button shortmedium" [class.primary]="settings.punishment" (click)="settings.togglePunishment()">
      Punishment
    </button>
    <button class="button shortmedium" [class.primary]="settings.saboteur" (click)="settings.toggleSaboteur()">
      Saboteur
    </button>
    <button class="button shortmedium" [class.primary]="settings.promoter" (click)="settings.togglePromoter()">
      Promoter
    </button>
    <mat-divider></mat-divider>
    <button class="button shortmedium" [class.primary]="settings.life === 3" (click)="settings.toggleTreeLives()">
      Tree Lives
    </button>
    <button class="button shortmedium" [class.primary]="settings.life === 5" (click)="settings.toggleFiveLives()">
      Five Lives
    </button>
    <button class="button shortmedium" [class.primary]="settings.life === 7" (click)="settings.toggleSevenLives()">
      Seven Lives
    </button>
    <button class="button shortmedium" [class.primary]="settings.life === 10" (click)="settings.toggleTenLives()">
      Ten Lives
    </button>
    <mat-divider></mat-divider>
    <button class="button shortmedium" [class.primary]="settings.firstDeath" (click)="settings.toggleFirstDeath()">
      First Death
    </button>
    <button class="button shortmedium" [class.primary]="settings.palFirst" (click)="settings.togglePalFirst()">
      Pal First
    </button>
    <button class="button shortmedium" (click)="settings.setFavorite()">
      FAV
    </button>
    <button class="button shortmedium" (click)="settings.setInverseFavorite()">
      iFAV
    </button>
    <mat-divider></mat-divider>

    <h2>Field's order</h2>
    <button class="button shortmedium" [class.primary]="settings.style==0" (click)="settings.setStyle(0)">
      Ascending
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==4" (click)="settings.setStyle(4)">
      Descending
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==1" (click)="settings.setStyle(1)">
      Clockwise
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==2" (click)="settings.setStyle(2)">
      RClockwise
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==8" (click)="settings.setStyle(8)">
      TripleClock
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==9" (click)="settings.setStyle(9)">
      ZoneClock
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==3" (click)="settings.setStyle(3)">
      Random
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==6" (click)="settings.setStyle(6)">
      Random11
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==5" (click)="settings.setStyle(5)">
      Recurrence
    </button>
    <button class="button shortmedium" [class.primary]="settings.style==7" (click)="settings.setStyle(7)">
      Pirate
    </button>
  </app-settings>

  <div class="side-left" [class.detailed]="application.settings.details">
    <app-game-toolbar [playground]="playground" *ngIf="!settingsOpen"></app-game-toolbar>
    <div class="player_container" *ngIf="!settingsOpen">
      <div class="hidden-info" [class.hidden]="hiddenInfo" (click)="toggleHiddenInfo()">{{getDistanceFromRandom()}}</div>
      <div class="player
        {{(i === game.actualPlayerIndex) ? 'highlighted' : ''}}
        {{settings.life !== 0 && player.isInactive() ? 'inactive' : ''}}"
           *ngFor="let player of game.players; let i = index">
        <app-player-score [player]="player" [scoreDisplayed]="false" [lifeDisplayed]="settings.life !== 0" [hasContent]="false">
        </app-player-score>
      </div>
    </div>
  </div>
  <app-number-plate-visual [playground]="playground" *ngIf="!settingsOpen && !application.settings.details">
  </app-number-plate-visual>
  <app-number-plate [playground]="playground" *ngIf="!settingsOpen && application.settings.details"></app-number-plate>
</div>
